import styled from "styled-components";
import { lighten, darken } from "polished";
import DayPicker, { NavbarElementProps } from "react-day-picker";
import { Icon } from "../common";
import { format } from "date-fns";
import { useContext } from "react";
import { LocaleContext } from "../locale";
import { getDateFnsLocale } from "../../lib/utils";

const StyledDayPicker: typeof DayPicker = styled(DayPicker)`
  /* Default styles --------------------------------------------- */
  .DayPicker {
    display: inline-block;
    font-size: 1rem;
  }

  .DayPicker-wrapper {
    position: relative;
    flex-direction: row;
    padding-bottom: 1em;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .DayPicker-Months {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .DayPicker-Month {
    display: table;
    margin: 0 1em;
    margin-top: 1em;
    border-spacing: 0;
    border-collapse: collapse;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .DayPicker-NavBar {
  }

  .DayPicker-NavButton {
    position: absolute;
    top: 1em;
    right: 1.5em;
    left: auto;
    display: inline-block;
    margin-top: 2px;
    width: 1.25em;
    height: 1.25em;
    background-position: center;
    background-size: 50%;
    background-repeat: no-repeat;
    color: #8b9898;
    cursor: pointer;
  }

  .DayPicker-NavButton:hover {
    opacity: 0.8;
  }

  .DayPicker-NavButton--prev {
    margin-right: 1.5em;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC");
  }

  .DayPicker-NavButton--next {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==");
  }

  .DayPicker-NavButton--interactionDisabled {
    display: none;
  }

  .DayPicker-Caption {
    display: table-caption;
    margin-bottom: 0.5em;
    padding: 0 0.5em;
    text-align: left;
  }

  .DayPicker-Caption > div {
    font-weight: 500;
    font-size: 1.15em;
  }

  .DayPicker-Weekdays {
    display: table-header-group;
    margin-top: 1em;
  }

  .DayPicker-WeekdaysRow {
    display: table-row;
  }

  .DayPicker-Weekday {
    display: table-cell;
    padding: 0.5em;
    color: #8b9898;
    text-align: center;
    font-size: 0.875em;
  }

  .DayPicker-Weekday abbr[title] {
    border-bottom: none;
    text-decoration: none;
  }

  .DayPicker-Body {
    display: table-row-group;
  }

  .DayPicker-Week {
    display: table-row;
  }

  .DayPicker-Day {
    display: table-cell;
    padding: 0.5em;
    border-radius: ${({ theme }) => theme.radii.circle}px;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
  }

  .DayPicker-WeekNumber {
    display: table-cell;
    padding: 0.5em;
    min-width: 1em;
    border-right: 1px solid #eaecec;
    color: #8b9898;
    vertical-align: middle;
    text-align: right;
    font-size: 0.75em;
    cursor: pointer;
  }

  .DayPicker--interactionDisabled .DayPicker-Day {
    cursor: default;
  }

  .DayPicker-Footer {
    padding-top: 0.5em;
  }

  .DayPicker-TodayButton {
    border: none;
    background-color: transparent;
    background-image: none;
    box-shadow: none;
    color: #4a90e2;
    font-size: 0.875em;
    cursor: pointer;
  }

  /* Default modifiers */

  .DayPicker-Day--today {
    color: #d0021b;
    font-weight: 700;
  }

  .DayPicker-Day--outside {
    color: #8b9898;
    cursor: default;
  }

  .DayPicker-Day--disabled {
    color: #dce0e0;
    cursor: default;
    opacity: 0.5;

    /* background-color: #eff1f1; */
  }

  /* Example modifiers */

  .DayPicker-Day--sunday {
    background-color: #f7f8f8;
  }

  .DayPicker-Day--sunday:not(.DayPicker-Day--today) {
    color: #dce0e0;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;

    background-color: #4a90e2;
    color: #f0f8ff;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: #51a0fa;
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: #f0f8ff;
  }

  /* DayPickerInput */

  .DayPickerInput {
    display: inline-block;
  }

  .DayPickerInput-OverlayWrapper {
    position: relative;
  }

  .DayPickerInput-Overlay {
    position: absolute;
    left: 0;
    z-index: 1;

    background: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  }

  /* Custom styles --------------------------------------------- */

  .DayPicker-wrapper {
    background-color: white;
    padding: 0;
    box-sizing: border-box;
    &:focus {
      outline: none;
    }
  }

  .DayPicker-Month {
    margin: 0;
  }

  .DayPicker-Weekday {
    color: ${({ theme }) => darken(0.1, theme.colors.gray)};
  }

  .DayPicker-Day {
    padding: 0;
    width: 48px !important;
    height: 48px;
    color: ${({ theme }) => theme.colors.labradorBlack};
    font-size: 1em;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: ${({ theme }) => theme.colors.springGreen};
    color: ${({ theme }) => theme.colors.samoyedWhite};
    border-radius: 0;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: ${({ theme }) => theme.colors.springGreen};
  }

  .DayPicker-Day:focus {
    outline: none;
  }

  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover,
  &.Range
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: ${({ theme }) => lighten(0.4, theme.colors.springGreen)};
    color: ${({ theme }) => theme.colors.springGreen};
    border-radius: 0;
  }

  .DayPicker-Caption {
    margin: 0;
    padding: 0;
    height: 48px;
    margin-bottom: 1em;
  }

  .DayPicker-Caption > div {
    text-align: center;
    line-height: 48px;
    font-size: ${({ theme }) => theme.fontSizes[5]}px;
    font-weight: ${({ theme }) => theme.fontWeights.book};
  }

  /* Custom ContactSitterDatePicker styles --------------------------------------------- */

  .ContactSitterDatePicker {
    padding-left: 0;
    padding-right: 0;
  }
`;

const NavBarWrapper = styled.div`
  position: absolute;
  left: 2em;
  right: 2em;
  top: 2em;
  flex: 1;
  display: inline-flex;
  justify-content: space-between;
`;

const NavButton = styled.span`
  display: block;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.radii.circle}px;
  width: 48px;
  height: 48px;
  font-size: 1em;
  line-height: 48px;
  text-align: center;
  color: ${({ theme }) => theme.colors.springGreen};
  border: 1px solid ${({ theme }) => theme.colors.springGreen};
  margin: 0;
`;

const CustomNavbar = ({
  onPreviousClick,
  onNextClick,
  className
}: NavbarElementProps) => {
  return (
    <NavBarWrapper className={className}>
      <NavButton onClick={() => onPreviousClick()}>
        <Icon name="prev" color="springGreen" />
      </NavButton>
      <NavButton onClick={() => onNextClick()}>
        <Icon name="next" color="springGreen" />
      </NavButton>
    </NavBarWrapper>
  );
};

interface Props {
  numberOfMonths?: number;
  [x: string]: any;
}

function formatDay(d: Date, locale = "en") {
  return d.toDateString();
}

function formatMonthTitle(d: Date, locale = "en") {
  return format(d, "MMMM yyyy", { locale: getDateFnsLocale(locale) });
}

function formatWeekdayShort(i: number, locale) {
  return getDateFnsLocale(locale).localize.day(i, {
    width: "short"
  });
}

function formatWeekdayLong(i: number, locale) {
  return getDateFnsLocale(locale).localize.day(i, {
    width: "wide"
  });
}

function getFirstDayOfWeek(locale: string) {
  return getDateFnsLocale(locale).options?.weekStartsOn || 1;
}

export const localeUtils = {
  formatDay,
  formatMonthTitle,
  formatWeekdayShort,
  formatWeekdayLong,
  getFirstDayOfWeek,
  getMonths(locale?: string) {
    return ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"] as [
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string
    ];
  }
};

const CustomDayPicker = ({ numberOfMonths, ...rest }: Props) => {
  const { locale } = useContext(LocaleContext);

  return (
    <StyledDayPicker
      numberOfMonths={numberOfMonths}
      navbarElement={CustomNavbar}
      localeUtils={localeUtils}
      locale={locale}
      {...rest}
    />
  );
};

CustomDayPicker.defaultProps = {
  numberOfMonths: 2
};

export default CustomDayPicker;

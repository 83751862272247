import { useState } from "react";
import CustomDayPicker from "./CustomDayPicker";
import { DayModifiers, DateUtils } from "react-day-picker";

interface Props {
  onChange?: (dates: Date[]) => void;
  value?: Date[];
  [x: string]: any;
}
function MultipleDatesPicker({ value, onChange, ...rest }: Props) {
  const [selectedDays, setSelectedDates] = useState<Date[]>(value);

  const handleDayClick = (day: Date, { selected, disabled }: DayModifiers) => {
    if (disabled && !selected) {
      return;
    }
    if (selected) {
      const selectedIndex = selectedDays.findIndex(selectedDay =>
        DateUtils.isSameDay(selectedDay, day)
      );
      selectedDays.splice(selectedIndex, 1);
    } else {
      selectedDays.push(day);
    }

    const newDates = [...selectedDays];
    setSelectedDates(newDates);
    onChange(newDates);
  };

  return (
    <CustomDayPicker
      selectedDays={selectedDays}
      onDayClick={handleDayClick}
      {...rest}
    />
  );
}

MultipleDatesPicker.defaultProps = {
  value: []
};

export default MultipleDatesPicker;

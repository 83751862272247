import { useState } from "react";
import CustomDayPicker from "./CustomDayPicker";
import { DateUtils } from "react-day-picker";

const blankState = {
  from: undefined,
  to: undefined,
  enteredTo: undefined
};

interface Props {
  onChange?: ({ from, to }: { from: Date; to: Date }) => void;
  from?: Date;
  to?: Date;
  isInvalidDateRange?: (fromDay, toDay) => boolean;
  modifiers?: any;
  [x: string]: any;
}

function DatesRangePicker({
  from,
  to,
  onChange,
  isInvalidDateRange,
  modifiers,
  ...rest
}: Props) {
  const [state, setState] = useState({
    from,
    to,
    enteredTo: null // Keep track of the last day for mouseEnter.
  });

  const isSelectingFirstDay = (from, to, day) => {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  };

  const isSelectingSameDay = (from, to, day) => {
    const isSameDay = from && DateUtils.isSameDay(day, from);
    const isRangeSelected = from && to;
    return !from || isSameDay || isRangeSelected;
  };

  const resetDates = () => {
    setState(blankState);
  };

  const handleDayClick = (day, modifiers, e) => {
    const { disabled } = modifiers;
    if (disabled) {
      return;
    }
    const { from, to } = state;
    if (from && to && day >= from && day <= to) {
      resetDates();
      onChange({ from: undefined, to: undefined });
      return;
    }
    if (isSelectingFirstDay(from, to, day)) {
      setState({ from: day, to: undefined, enteredTo: undefined });
    } else if (isSelectingSameDay(from, to, day)) {
      resetDates();
      onChange({ from: undefined, to: undefined });
    } else {
      if (!isInvalidDateRange || !isInvalidDateRange(state.from, day)) {
        setState({ ...state, to: day, enteredTo: day });
        onChange({ from: state.from, to: day });
      } else {
        setState({ from: day, to: undefined, enteredTo: undefined });
        onChange({ from: day, to: undefined });
      }
    }
  };

  const handleDayMouseEnter = day => {
    const { from, to } = state;
    if (!isSelectingFirstDay(from, to, day)) {
      setState({ ...state, enteredTo: day });
    }
  };

  const defaultModifiers = {
    start: state.from,
    end: state.enteredTo || state.to,
    ...modifiers
  };
  const disabledDays = { before: state.from };
  const selectedDays = [
    state.from,
    { from: state.from, to: state.enteredTo || state.to }
  ];

  return (
    <CustomDayPicker
      className="Range"
      initialMonth={from}
      selectedDays={selectedDays}
      disabledDays={disabledDays}
      modifiers={defaultModifiers}
      onDayClick={handleDayClick}
      onDayMouseEnter={handleDayMouseEnter}
      {...rest}
    />
  );
}

DatesRangePicker.defaultProps = {};

export default DatesRangePicker;
